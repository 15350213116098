import React from 'react'
import styled from 'styled-components'

import { FilledButton, PageTitle, Image, FancyText, BackgroundImage, CtaGiftVoucher } from '../components/common'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Container, ContentContainer, Row } from '../components/utils'

import HMCBRLogo from '../images/HMC_Background_Black.svg'

import { usePricedServicesData } from '../hooks'

const ServiceDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    text-align: center;

    .gatsby-image-wrapper {
      margin-top: ${props => props.theme.spacing.xs};
      margin-bottom: ${props => props.theme.spacing.xs};
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: ${props => props.theme.spacing.sm};
  }
`
const ServicePriceDetails = styled.div``

const ServicePrice = styled.div`
  ${Row} {
    padding-top: ${props => props.theme.spacing.sm};
    padding-bottom: ${props => props.theme.spacing.sm};
    border-bottom: solid 1px #eee;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      text-align: center;
      padding-top: ${props => props.theme.spacing.xs};
      padding-bottom: ${props => props.theme.spacing.xs};
    }

    &:last-child {
      border-bottom: none;
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        margin-bottom: 20px;
      }

      @media (max-width: ${props => props.theme.breakpoints.sm}) {
        margin-bottom: 0;
      }
    }
  }
`

const ServicesPage = ({ location }) => {
  const { pricedServicesData } = usePricedServicesData()
  return (
    <Layout location={location}>
      <SEO title="Services" />
      <PageTitle
        title="Services"
        description="Sports & Remedial Massage Therapy services to cater to any client's massage needs."
      />
      <Container>
        <ContentContainer py="md">
          <Row col={2} gap={80} alignCenter>
            <ServiceDesc>
              <h2>{pricedServicesData.title}</h2>
              <p>{pricedServicesData.description}</p>
              <ServicePrice>
                {pricedServicesData.pricing.map(pricing => (
                  <Row col={2} alignCenter key={pricing.time}>
                    <ServicePriceDetails>
                      <h3>{pricing.time}mins</h3>
                      <h4>${pricing.price}</h4>
                      <p>{pricing.description}</p>
                    </ServicePriceDetails>
                    <FilledButton
                      to="/book"
                      className="-left -mobileCenter"
                      state={{ productId: pricing.timelyProductId }}
                    >
                      Book Now
                    </FilledButton>
                  </Row>
                ))}
              </ServicePrice>
            </ServiceDesc>
            <Image fluid={pricedServicesData.image.asset.fluid} alt={pricedServicesData.altText} />
          </Row>
        </ContentContainer>
      </Container>
      <CtaGiftVoucher />
      <Container
        bg="greyMedDark"
        css={`
          color: white;
        `}
      >
        <ContentContainer py="md">
          <BackgroundImage opacity="0.05" src={HMCBRLogo} alt="HMC Logo flair" />
          <Row col={1} gap={80} pb="md">
            <div>
              <h3>{pricedServicesData.additionalOneTitle}</h3>
              <FancyText node={({ node }) => node} blocks={pricedServicesData._rawAdditionalOneDescription} />
            </div>
          </Row>
          <Row col={1} gap={80}>
            <div>
              <h3>{pricedServicesData.additionalTwoTitle}</h3>
              <FancyText node={({ node }) => node} blocks={pricedServicesData._rawAdditionalTwoDescription} />
              <Row col={2} pt="xs" gap={100}>
                <FancyText node={({ node }) => node} blocks={pricedServicesData._rawAdditionalTwoA} />
                &nbsp;
                <FancyText node={({ node }) => node} blocks={pricedServicesData._rawAdditionalTwoB} />
              </Row>
            </div>
          </Row>
        </ContentContainer>
      </Container>
      <Container>
        <ContentContainer py="md">
          <Row col={2} gap={80}>
            <ServiceDesc>
              <h2>{pricedServicesData.otherTwoTitle}</h2>
              <FancyText node={({ node }) => node} blocks={pricedServicesData._rawOtherTwoDescription} />
              <Image fluid={pricedServicesData.otherTwoImage.asset.fluid} alt={pricedServicesData.otherTwoAltText} />
            </ServiceDesc>
            <ServiceDesc
              css={`
                justify-content: flex-start;
              `}
            >
              <h2>{pricedServicesData.otherOneTitle}</h2>
              <FancyText node={({ node }) => node} blocks={pricedServicesData._rawOtherOneDescription} />
              <div
                css={`
                  margin-top: auto;
                `}
              >
                <Image fluid={pricedServicesData.otherOneImage.asset.fluid} alt={pricedServicesData.otherOneAltText} />
              </div>
            </ServiceDesc>
          </Row>
        </ContentContainer>
      </Container>
    </Layout>
  )
}

export default ServicesPage
